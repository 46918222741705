import React from 'react'
import projects from '../assets/projects.json';
import SmallCard from "../components/Projects/SmallCard";

export type Project = {
    title: string;
    desc: string;
    link: string;
    github: string;
}

export default function Projects() {
    return (
        <div className={'w-full px-20'}>
            <h1 className="justify-start mt-10 lg:ml-12 lg:mt-12">Projects</h1>
            <span className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3"}>
                {projects.map((project: Project, idx) => <SmallCard key={idx} project={project} />)}
            </span>
        </div>
    )
}
