import { FaReact, FaPython } from 'react-icons/fa'
import { DiDart } from 'react-icons/di'
import { SiFlask, SiTypescript, SiFirebase } from 'react-icons/si'
import React from 'react'

export default function About() {
    return (
        <div
            className="md:w-full w-80"
        >
            <h1 className="justify-start mt-10 lg:ml-12 lg:mt-12">About me</h1>
            <p className="md:ml-4 mr-10 ml-2 md:mx-24 lg:ml-24 text-justify">
                I am a graduate of the University of Dundee with an upper second class honours degree
                in Applied Computing. I am currently employed at Neirfeno
                in Dundee as a Junior Software Engineer and love what I do. My role involves
                creating npm packages using typescript with test driven development, native mobile applications
                using React Native, websites using HTML & CSS and writing articles about the technologies I use and
                the lessons I have learned at work. I am also a member of the Dundee and Angus Chambers of Commerce and
                attend their events in order to integrate myself into the local business community.
            </p>

            <div className="flex flex-col relative justify-center">
                <h3 className="mt-10 lg:ml-12 lg:mt-12 mr-10 mb-5">
                    Frameworks & Languages I have been using recently:
                </h3>
                <ul className="framework-list inline-grid md:ml-4 mr-8 ml-2 md:mx-20 lg:ml-24">
                    <li>
                        <FaReact className="mr-2" /> React & React Native
                    </li>
                    <li>
                        <SiFlask className="mr-2" /> Flask
                    </li>
                    <li>
                        <DiDart className="mr-2" /> Flutter
                    </li>
                    <li>
                        <FaPython className="mr-2" /> Python
                    </li>
                    <li>
                        <SiTypescript className="mr-2" /> Typescript
                    </li>
                    <li>
                        <SiFirebase className="mr-2" /> Firebase
                    </li>
                </ul>
            </div>
        </div>
    )
}
