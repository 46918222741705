import React from 'react'
import '../animation/typewriter.css'
import { FaExternalLinkAlt } from 'react-icons/fa'
const resume: string = require('../assets/img/resume.svg').default
const pdf_resume = require('../assets/Stuart_Florence_resume.pdf')

export default function Titlebar() {
    return (
        <div
            className="title-image relative w-full h-full bg-no-repeat bg-cover"
        >
            <div className="intro-text w-full">
                <h2 className="animate__animated animate__bounceInDown">
                    Hi my name is,
                </h2>
                <span className="inline-block">
                    <h1 className="text-white"> Stuart Florence, </h1>
                </span>{' '}
                <br />
                <span className="inline-block">
                    <h1 className="type-name pb-2"> Junior Software Engineer. </h1>
                </span>
                <br />
                <a href={pdf_resume} target="_blank" rel="noopener noreferrer" className="bg-transparent resume my-3 border border-white hover:bg-blue-600 font-bold py-2 px-2 rounded inline-flex items-center">
                        <img
                            className="mr-2 w-5 h-5 lg:w-12 lg:h-12"
                            src={resume}
                            alt="resume buttons"
                        ></img>
                        <h2 className="flex">
                            My Resume <FaExternalLinkAlt className={"ml-1 w-3 h-3 lg:w-5 lg:h-5"} />
                        </h2>
                </a>
            </div>
            {/* <br />
            <h1 className='w-full intro-text-hi'>Stuart Florence, <br /> Assistant Developer.</h1> */}
        </div>
    )
}
