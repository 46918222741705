import React from 'react'
import experience from '../assets/experience.json'
import TimelineEntry from '../components/Timeline/TimelineEntry'
import { TimelineEntryT } from '../types/types'

export default function Timeline() {
    return (
        <div
            className="md:w-full w-80 mb-12"
            data-scroll
            data-scroll-direction="vertical"
            data-scroll-speed="2"
        >
            <h1 className="justify-start mt-10 lg:ml-12 lg:mt-12 mb-10">
                Employment Timeline
            </h1>
            <ol className="relative border-l border-gray-700 ml-2 md:ml-20 sm:ml-0 pr-6 md:pr-24">
                {experience.map((entry: TimelineEntryT, idx) => (
                    <TimelineEntry entry={entry} key={idx} />
                ))}
            </ol>
        </div>
    )
}
