import React, { FC } from 'react'
import { TimelineEntryI } from '../../interfaces/interfaces'
import companyLogos from './companyLogos'

const TimelineEntry: FC<TimelineEntryI> = ({ entry }) => {
    const key = entry.companyLogo as keyof typeof companyLogos
    const logo = companyLogos[key] ?? ''
    return (
        <li className="mb-10 ml-4">
            <div className="absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 border border-gray-900 bg-gray-700"></div>
            <time className="mb-1 text-sm font-normal leading-none text-white">
                {entry.startDate}
            </time>
            <div className="flex items-center">
                {entry.companyLogo !== "" ? <img className="w-16 h-16" src={logo} alt="logo"/> : <div className="w-16 h-16"></div>}
                <div className="flex flex-col ml-5">
                    <h3 className="md:text-lg text-md font-semibold text-white">
                        {entry.companyName} - {entry.jobTitle}
                    </h3>
                    <p className="mb-4 text-sm md:text-base font-normal text-white">
                        {entry.description}
                    </p>
                    <p>{entry.tools.join(' | ')}</p>
                </div>
            </div>
        </li>
    )
}

export default TimelineEntry
