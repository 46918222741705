import React from 'react';
import {useState, useEffect} from 'react';

export default function Sidebar() {
    const github = require('../assets/img/logos/github.svg').default;
    const linkedin = require('../assets/img/logos/linkedin.svg').default;
    const mail = require('../assets/img/logos/mail.svg').default;

    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText('stuart.flo@gmail.com').then(r => r);
        setIsCopied(true);
    }

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        }
    })

    return(
        <div className="sidebar flex flex-col items-start ml-2 z-50">
            
            <a href="https://github.com/Stuflo19" className='m-2 icon' target="_blank" rel="noopener noreferrer">
                <img src={github} alt="link to my github" className='icon-size' />
            </a>
            <a href="https://www.linkedin.com/in/stuart-florence/" className='m-2 icon' target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="link to my linkedin" className='icon-size' />
            </a>
            <button onClick={copyToClipboard} className="m-2 icon flex flex-col">
                <span className='absolute right-12 text-white'>{isCopied ? 'Copied!' : null}</span>
                <img src={mail} alt="copy my email address" className='icon-size' />
            </button>

        </div>
    )
}
