import React from 'react';
import { Project } from "../../screens/Projects";
import "./SmallCard.css";
import { FaExternalLinkAlt, FaGithub } from "react-icons/fa";

type ProjectProps = {
    project: Project;
}

const SmallCard = ({project}: ProjectProps) => {
    return(
        <a href={project.github || project.link} target={"_blank"} rel={"noreferrer"} className={"SmallCard"}>
            <h3>{project.title}</h3>
            <p>{project.desc}</p>
            <span className={"flex flex-row gap-3 justify-end"}>
                <a href={project.link}>{project.link ? <FaExternalLinkAlt size={20} /> : null}</a>
                <a href={project.github}>{project.github ? <FaGithub size={20} /> : null}</a>
            </span>
        </a>
    )
}

export default SmallCard;
