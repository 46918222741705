import React from 'react'
import 'animate.css'
import Titlebar from './screens/titlebar'
import Timeline from './screens/Timeline'
import About from './screens/About'
import Projects from './screens/Projects'
import Sidebar from './screens/Sidebar'
import './firebase.js'

function App() {
    return (
            <main className="bg-slate-900">
                <Sidebar />
                {/* <body> */}
                <section
                    className="title-section"
                >
                    <Titlebar />
                </section>

                <section
                    id="about"
                    className="content-section"
                >
                    <About />
                </section>

                <section
                    id="projects"
                    className="content-section"
                >
                    <Projects />
                </section>

                <section className="content-section">
                    <Timeline />
                </section>

                {/* <Footer /> */}
                {/* </body> */}
            </main>
    )
}
export default App
